<script>
export default {
  name: "driverInfo",
  props: ["vdata","sdata"],
  data() {
    return {
      regnum: "ss",
      drivernum: null,
      routeId: null,
      drivername: null
    };
  },

  mounted() {
    this.regnum = this.sdata.regId;
    this.routeId = this.sdata.routeId;
    this.drivername = this.vdata.client;
      this.drivernum = this.vdata.clientphone1;
    // if (
    //   this.data.driverstatus1 == "active" &&
    //   this.data.driverstatus2 == "inactive"
    // ) {
    //   this.drivername = this.data.driverName;
    //   this.drivernum = this.data.driverMobile;
    // } else if (
    //   this.data.driverstatus1 == "inactive" &&
    //   this.data.driverstatus2 == "active"
    // ) {
    //   this.drivername = this.data.driverName2;
    //   this.drivernum = this.data.driverMobile2;
    // } else if (
    //   (this.data.driverstatus1 == "active" &&
    //     this.data.driverstatus2 == "active") ||
    //   (this.data.driverstatus1 == "inactive" &&
    //     this.data.driverstatus2 == "inactive")
    // ) {
    //   this.drivername = "Not Assigned";
    //   this.drivernum = "Not Assigned";
    // } else {
    //   this.drivername = "Not Assigned";
    //   this.drivernum = "Not Assigned";
    // }
  }
};
</script>
<template>
  <div>
    <div>
      <v-card flat style="background-color:teal">
        <v-card-text style="color: white;">Driver Information</v-card-text>
      </v-card>
      <!-- <v-row align="center" justify="center">
        <v-col cols="12">
          <v-toolbar-title class="white--text">
            Driver Information
          </v-toolbar-title>
        </v-col>
      </v-row> -->
    </div>

    <v-flex xs12>
      <v-card color="#033" class="white--text">
        <v-layout row>
          <v-flex xs12 sm12 md4 class="my-auto">
            <v-img
              src="../../../assets/driver.svg"
              height="80px"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs6 sm6 md3>
            <v-card-title primary-title>
              <div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Reg No.
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Route Id
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Driver Name
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Driver Mobile
                </div>
              </div>
            </v-card-title>
          </v-flex>
          <v-flex xs6 sm6 md3>
            <v-card-title primary-title>
              <div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ regnum }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ routeId }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ drivername }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ drivernum }}
                </div>
              </div>
            </v-card-title>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.white--text {
  opacity: 0.7;
}
td {
  text-align: center;
}
.v-card__text {
  padding: 0px !important;
  width: 100%;
}
img {
  display: flex;
  margin: 0 auto;
  padding: 6px;
}

.card__text {
  text-align: center !important;
}
</style>
